<template>
  <v-navigation-drawer
    app
    right
    clipped
    width="290"
    v-model="model"
  >
    <perfect-scrollbar style="height: 100%">
      <div class="mt-4 mx-2" >
        <calendar-date-selection v-if="isCalendarView" />
        <date-selection v-else />
      </div>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import CalendarDateSelection from './filters/CalendarDateSelection.vue';
import DateSelection from './filters/DateSelection.vue';

export default {
  name: 'PlanningDrawer',
  components: {
    CalendarDateSelection,
    DateSelection,
  },
  computed: {
    ...mapState('receivingProductionVista', ['drawer']),
    ...mapGetters('receivingProductionVista', ['isCalendarView']),
    model: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
  },
  methods: {
    ...mapMutations('receivingProductionVista', ['setDrawer']),
  },
};
</script>
