<template>
  <div style="height:100%">
    <portal to="app-header">
      <span>{{ $t('receivingproductionplanning') }}</span>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-on="on"
            v-bind="attrs"
            class="ml-2 mb-1"
            @click="fetchData"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        {{ $t('lastRefreshed') }} <strong>{{ lastRefreshedAt }}</strong>
      </v-tooltip>
    </portal>
    <portal to="app-extension">
      <v-toolbar
        flat
        dense
        :color="$vuetify.theme.dark ? '#121212': ''"
      >
      <!-- <span class="title">{{ $t('receivings') }}</span> -->
      <v-spacer></v-spacer>
      <v-btn
        small
        color="primary"
        class="text-none mr-2"
        :to="{ name: 'create-new-receiving' }"
      >
        Create New
      </v-btn>
      <v-btn
        small
        outlined
        color="primary"
        class="text-none"
        @click="toggleDrawer(true)"
      >
        <v-icon small left>mdi-filter-variant</v-icon>
        Filters
      </v-btn>
      </v-toolbar>
    </portal>
    <v-row class="pt-4 px-4">
      <v-col cols="12" xl="9" class="text-justify">
        <base-loading v-if="loading"></base-loading>
        <no-result v-if="!receivingList.length && !loading"></no-result>
        <v-data-table
          :headers="headers"
          :items="receivingList"
          hide-default-footer
          disable-pagination
          v-if="!loading && receivingList.length"
        >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-on="on"
                v-bind="attrs"
                @click="$router.push({
                  name: 'edit-receiving',
                  params: { id: item._id },
                })"
                :disabled="item.status === 'aborted' || item.status === 'completed'"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
              Edit
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-on="on"
                v-bind="attrs"
                @click="AbortPlan(item)"
                :disabled="item.status !== 'inProgress'"
              >
                <v-icon color="error">mdi-close-octagon-outline</v-icon>
              </v-btn>
            </template>
              Abort Plan
          </v-tooltip> -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-on="on"
                v-bind="attrs"
                @click="deletePlan(item)"
              >
                <v-icon color="error">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
              Delete
          </v-tooltip>
        </template>
      </v-data-table>
      <planning-drawer />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';
import BaseLoading from '../components/base/loading.vue';
import NoResult from '../components/base/NoResult.vue';
import { newform } from '../components/planform.json';
import PlanningDrawer from '../components/PlanningDrawer.vue';

export default {
  name: 'Home',
  components: {
    BaseLoading,
    NoResult,
    PlanningDrawer,
  },
  data() {
    return {
      headers: [],
      planData: [],
      loading: false,
    };
  },
  computed: {
    ...mapState('receivingProductionVista', ['receivingList', 'lastRefreshedAt']),
    ...mapGetters('receivingProductionVista', ['isCalendarView']),
    element() {
      return newform.elementName;
    },
  },
  mounted() {
    this.setHeader();
    this.fetchData();
    this.setExtendedHeader(true);
  },
  methods: {
    ...mapActions('receivingProductionVista', ['getRecords', 'updateRecordById', 'deleteRecordById', 'fetchReceivingList']),
    ...mapMutations('helper', ['setExtendedHeader']),
    ...mapMutations('receivingProductionVista', ['toggleDrawer']),
    async fetchData() {
      this.loading = true;
      // const res = await this.getRecords(this.element);
      // if (res) {
      //   this.planData = res;
      // }
      await this.fetchReceivingList();
      this.loading = false;
    },
    async AbortPlan(record) {
      const currentTime = new Date().getTime();
      await this.updateRecordById({
        elementName: this.element,
        payload: {
          status: 'aborted',
          actualend: currentTime,
        },
        id: record._id,
      });
      this.fetchData();
    },
    async deletePlan(item) {
      await this.deleteRecordById({ elementName: this.element, id: item._id });
      this.fetchData();
    },
    setHeader() {
      const filteredData = newform.fields.filter((v) => !v.hidefromtable);
      this.headers = filteredData.map((v) => ({
        text: v.lable,
        value: v.key,
        sortable: false,
      }));
      this.headers = [
        ...this.headers,
        {
          text: '',
          value: 'actions',
          sortable: false,
        }];
    },
  },
  async created() {
    this.loading = true;
    await this.fetchReceivingList();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.border-round {
  border-radius: 10px;
}
#home-container {
  height: calc(100vh - 220px);
  overflow: auto;
}
</style>
